import { TEMPLATE_API_URL } from '../config';

const axios = require('axios');


function kebabCaseToCamel(str) {
  return str.replace(/(-\w)/g, matches => matches[1].toUpperCase());
}

class API {
  constructor() {
    this.url = TEMPLATE_API_URL;
    this.endpoints = {};
  }

  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    /**
     * If there is a - in the entity.name, then change it
     * to camelCase. E.g
     * ```
     * myApi.createEntity({ name : 'foo-bar'})
     * myApi.endpoints.fooBar.getAll(...)
     */

    const name = kebabCaseToCamel(entity.name);
    this.endpoints[name] = this.createBasicCRUDEndpoints(entity);
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this));
  }

  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints({ name }) {
    const endpoints = {};

    const resourceURL = `${this.url}/${name}`;

    endpoints.Pay = (toCreate, config = {}) => axios.post(resourceURL, toCreate, config);

    endpoints.Open = (config = {}) => axios.get(resourceURL, config);

    endpoints.Close = (config = {}) => axios.get(resourceURL, config);

    endpoints.Print = (toCreate, config = {}) => axios.post(resourceURL, toCreate, config);

    return endpoints;
  }
}

export default API;
