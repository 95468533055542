
import React, { Component } from 'react';
import styled from 'styled-components';
import TextInputGroup from '../../shared/TextInputGroup';
import ModalButton from '../../shared/ModalButton';
import GeneralMessage from './GeneralMessage';

import KeyBoardButtons from '../KeyBoardButtons';
import API from '../../../../api/API.js';
import GlobalModal from '../index';

const TotalWrapper = styled.ul`
margin:0;
padding:0;
`;
const Footer = styled.div`
margin: 0 auto;
display: table;
`;
const Wrapper = styled.div`
display: flex;
flex: 1;
margin-bottom: 10px;
`;
const LeftSide = styled.div`
width: 12em;
`;
const RightSide = styled.div`
width:100%;
`;
const FieldsRow = styled.div`
    margin-bottom:10px;
`;
const FullWrapper = styled.div`
display:flex;
flex-direction:column;
`;
const LTR = styled.span`direction:ltr;display: inline-block;`;

type Props = {
  orderDetails: Object,
  updateStatus: () => any
};

class Credit extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      getMoney: '',
      inCash: '',
      inCredit: props.orderDetails.afterDiscount,
      creditPayment: props.orderDetails.afterDiscount,
      backMoney: '',
      validationErrors: {},
      orderDetails: props.orderDetails,
      selectedOption: 1,
      Modal: {
        isOpen: false,
        headerContent: '',
      },
    };

    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleModal() {
    this.setState(prevState => ({ Modal: { isOpen: prevState.isOpen } }));
  }

  inCashChange(e) {
    const { orderDetails } = this.props;
    if (Number.isNaN(Number(e.target.value))) {
      return;
    }
    const { getMoney, selectedOption } = this.state;
    const calcInCredit = orderDetails.afterDiscount - e.target.value;


    const totalNumber = parseFloat(calcInCredit);
    let Payment = totalNumber / selectedOption;
    Payment = Payment.toFixed(2);


    this.setState({
      inCash: e.target.value,
      inCredit: calcInCredit < 0 ? '0.00' : calcInCredit.toFixed(2),
      creditPayment: calcInCredit < 0 ? '0.00' : Payment,
      backMoney: (getMoney === '' || e.target.value === '') ? '' : (e.target.value - getMoney).toFixed(2),
    });
  }


  pay = () => {
    const { orderDetails } = this.props;
    const { selectedOption, creditPayment, inCredit } = this.state;

    const totalNumber = parseFloat(inCredit) * 100;
    const eachPayment = parseFloat(creditPayment) * 100;
    const firstPay = totalNumber % Number(selectedOption);

    const myApi = new API();
    myApi.createEntity({ name: 'Pay' });
    myApi.endpoints.Pay.Pay({
      amount: totalNumber,
      creditTerms: 'CREDIT_TERMS_PAYMENTS',
      noPayments: Number(selectedOption) - 1,
      firstPayment: eachPayment + (firstPay * 100),
      notFirstPayment: eachPayment,
      creditNoPayments: 0,
      indexPayment: 'INDEX_PAYMENT_NONE',
      cashbackAmount: 0,
      authNum: null,
      motoPanEntry: null,
      printVoucher: false,
      context: 'payments - context',
      userData: null,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(({ res }) => {
      if (res.success) {
        this.setState(({
          Modal: {
            isOpen: true,
            headerContent: 'עבר בהצלחה',
          },
          currentContact: <GeneralMessage toggleModal={this.toggleModal} text="העסקה בוצעה בהצלחה" buttonText="אישור" />,

        }));
      } else {
        this.setState(({
          Modal: {
            isOpen: true,
            headerContent: 'תקלה בתקשורת',
          },
          currentContact: <GeneralMessage toggleModal={this.toggleModal} text="הכרטיס לא עבר, אנא פנה לשירות הטכני" buttonText="אישור" />,

        }));
      }
    }).catch(err => this.setState(({
      Modal: {
        isOpen: true,
        headerContent: 'בעיה טכנית',
      },
      currentContact: <GeneralMessage toggleModal={this.toggleModal} text="אנא פנה לשירות הטכני" buttonText="אישור" />,
    })));
  }

  componentWillUnmount() {
    const myApi = new API();
    myApi.createEntity({ name: 'Close' });
    myApi.endpoints.Close.Close({
    });
  }

  onChange(e) {
    if (Number.isNaN(Number(e.target.value))) {
      return;
    }

    const { orderDetails: { afterDiscount } } = this.props;
    const { inCash } = this.state;
    this.setState({
      getMoney: e.target.value,
      backMoney: (inCash === '' || e.target.value === '') ? '' : (inCash - e.target.value).toFixed(2),
    });
  }

  updateValue(e) {
    const {
      orderDetails: { afterDiscount },
    } = this.props;

    const value = e.target.getAttribute('data-value');

    const { getMoney } = this.state;

    switch (value) {
      case 'remove':
        this.setState({
          getMoney: getMoney === '' ? '' : getMoney.substr(0, getMoney.length - 1),
          backMoney: (Number(getMoney.substr(0, getMoney.length - 1)) - afterDiscount).toFixed(2),
        });
        break;
      default:
        if (Number.isNaN(Number(getMoney.concat(value)))) { return; }
        this.setState({
          getMoney: getMoney === '' ? value : getMoney.concat(value),
          backMoney: (Number(getMoney.concat(value)) - afterDiscount).toFixed(2),
        });
        break;
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { updateStatus, orderDetails: { afterDiscount } } = this.props;
    const {
      getMoney,
    } = this.state;
    let ErrorsFlag = false; // setState is asynchronous

    this.setState({
      validationErrors: [],
    });


    if (ErrorsFlag) {
      ErrorsFlag = false;
      return;
    }
    this.pay();
  }


  buildOptions =() => {
    const arr = [];

    for (let i = 1; i <= 36; i++) {
      arr.push(<option key={i} value={i}>{i}</option>);
    }
    return arr;
  }

  updateSelectOption = (event) => {
    const { orderDetails } = this.props;
    const { inCredit } = this.state;
    const totalNumber = parseFloat(inCredit);
    let Payment = totalNumber / event.target.value;
    Payment = Payment.toFixed(2);
    this.setState({ selectedOption: event.target.value, creditPayment: Payment });
  }

  render() {
    const {
      selectedOption, inCash, creditPayment, inCredit, getMoney, backMoney, validationErrors, orderDetails: { afterDiscount }, Modal, Modal: { isOpen }, currentContact,
    } = this.state;

    return (
      <FullWrapper>
        <Wrapper>

          <RightSide>
            <TotalWrapper>
              <FieldsRow>
                <span>סה״כ לתשלום:</span>
                <span>
                  {' '}
                  {afterDiscount}
                </span>
              </FieldsRow>
              <FieldsRow>
                <span>לתשלום באשראי:</span>
                <span>
                  {' '}
                  {inCredit}
                </span>
              </FieldsRow>
              <FieldsRow>
                <span>תשלומים:</span>
                <select onChange={this.updateSelectOption} value={selectedOption}>
                  {this.buildOptions()}
                </select>
                <span style={{ marginRight: '10px' }}>כל תשלום:</span>
                <span>{creditPayment}</span>
              </FieldsRow>
              <TextInputGroup
                name="inCash"
                text="תשלום במזומן:"
                InputType="text"
                InputValue={inCash}
                onChange={e => this.inCashChange(e)}
                error={validationErrors.inCash}
              />
              <TextInputGroup
                name="getMoney"
                text="התקבל במזומן:"
                InputType="text"
                InputValue={getMoney}
                onChange={e => this.onChange(e)}
                error={validationErrors.getMoney}
              />
              <FieldsRow>
                <span>עודף:</span>
                <LTR>
                  {' '}
                  {backMoney}
                </LTR>
              </FieldsRow>

            </TotalWrapper>
          </RightSide>
          <LeftSide>
            <KeyBoardButtons updateValue={e => this.updateValue(e)} />
          </LeftSide>
        </Wrapper>
        <Footer>
          <ModalButton handleButtonClick={e => this.onSubmit(e)}>שמור</ModalButton>
        </Footer>
        { isOpen
        && (
        <GlobalModal
          Modal={Modal}
          toggleModal={this.toggleModal}
        >
          {currentContact}
        </GlobalModal>
        )}
      </FullWrapper>
    );
  }
}
export default Credit;
