import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter,
  Route, Link, BrowserRouter as Router, Switch,
} from 'react-router-dom';

import App from './App';
import serviceWorker from './serviceWorker';
import TestConnection from './TestConnection';
// eslint-disable-next-line no-undef

const routing = (
  <HashRouter>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/test" component={TestConnection} />
      <Route component={App} />
    </Switch>
  </HashRouter>
);

ReactDOM.render(routing, document.getElementById('root'));
serviceWorker();
