import React from 'react';
import styled from 'styled-components';
import ModalButton from '../../shared/ModalButton';

const MessageText = styled.p`
`;
const Wrapper = styled.div`
display: flex;
flex: 1;
margin-bottom: 10px;
justify-content:center;
font-size:25px;
direction: rtl;
`;
const FullWrapper = styled.div`
display:flex;
flex-direction:column;
`;
const Footer = styled.div`
display:flex;
justify-content: space-around;
`;

type Props = {
  ConfirmDeleteProduct: () => void,
  toggleModal: () => void,
};

const RemoveProduct = (props: Props) => {
  const {
    ConfirmDeleteProduct,
    toggleModal,
  } = props;
  return (
    <FullWrapper>
      <Wrapper>
        <MessageText> האם אתה בטוח ?  </MessageText>
      </Wrapper>
      <Footer>
        <ModalButton handleButtonClick={() => ConfirmDeleteProduct()}>כן</ModalButton>
        <ModalButton handleButtonClick={() => toggleModal()} margin="0 15px 0 0">לא</ModalButton>
      </Footer>
    </FullWrapper>
  );
};
export default RemoveProduct;
