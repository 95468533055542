import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/productReducer';

const initialState = {
  cartProducts: [],
  cartProduct: { },
  orderDetails: {
    discountPercent: '', discountAmount: 0, total: 0, afterDiscount: 0,
  },
};

const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
  //  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  ),
);
export default store;
