import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Save } from 'styled-icons/fa-regular/Save';
import { MoneyBillAlt } from 'styled-icons/fa-regular/MoneyBillAlt';
import { CreditCard } from 'styled-icons/fa-regular/CreditCard';
import { FolderOpen } from 'styled-icons/fa-regular/FolderOpen';

import { cleanOrder } from '../../../actions/productActions';
import GlobalModal from '../modal/index';
import Cash from '../modal/content/Cash';
import Credit from '../modal/content/Credit';
import GeneralMessage from '../modal/content/GeneralMessage';

import SuccessMessage from '../modal/content/SuccessMessage';
import API from '../../../api/API.js';

import Button from '../shared/Button';

const Buttons = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
/* width:45%; */
margin-left:10px;
`;
const ButtonText = styled.div`
font-size:26px;
display: inline-block;
vertical-align: top;
margin-right: 5px;
`;
type Props = {
  orderDetails: any,
  clean: () => any,
};
// type State = {
//   orderDetails: any,
//   cleanOrder: any,
// }
class BottomButtons extends Component<Props> {
  constructor() {
    super();
    this.cashClick = this.cashClick.bind(this);
    this.creditClick = this.creditClick.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.SuccessMessageModal = this.SuccessMessageModal.bind(this);
    this.state = {
      Modal: {
        isOpen: false,
        headerContent: '',
        product: {},
      },
    };
    this.onUnload = this.onUnload.bind(this); // if you need to bind callback to this
  }


  componentDidMount() {
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload(event) {
    event.returnValue = '';
  }

  updateStatus() {
    const { clean } = this.props;
    clean();
    this.setState({
      Modal: {
        isOpen: false,
        headerContent: '',
      },
      currentContact: {},
    }, () => {
      this.SuccessMessageModal();
    });
  }

  SuccessMessageModal() {
    this.setState(({
      Modal: {
        isOpen: true,
        headerContent: 'סיום פעולה',
      },
      currentContact: <SuccessMessage toggleModal={this.toggleModal} />,
    }));
  }

  SaveOrder = () => {

  }

  OpenDrawer = () => {
    const myApi = new API();
    myApi.createEntity({ name: 'Print' });
    myApi.endpoints.Print.Print({
      filePath: '',
    }).then((response) => {
      this.setState(({
        Modal: {
          isOpen: false,
        },
      }));
    }).catch(err => this.setState(({
      Modal: {
        isOpen: true,
        headerContent: 'בעיה טכנית',
      },
      currentContact: <GeneralMessage toggleModal={this.toggleModal} text="אנא פנה לשירות הטכני" buttonText="אישור" />,
    })));
  }

  toggleModal() {
    this.setState(prevState => ({ Modal: { isOpen: prevState.isOpen } }));
  }

  cashClick() {
    const {
      orderDetails, orderDetails: { total },
    } = this.props;

    if (total === 0) { return; }
    this.setState(({
      Modal: {
        isOpen: true,
        headerContent: 'תשלום במזומן',
      },
      currentContact: <Cash orderDetails={orderDetails} updateStatus={this.updateStatus} />,
    }));
  }

  creditClick() {
    const {
      orderDetails, orderDetails: { total },
    } = this.props;
    if (total === 0) { return; }


    const myApi = new API();
    myApi.createEntity({ name: 'Open' });
    myApi.endpoints.Open.Open({
    }).then((response) => {
      this.setState(({
        Modal: {
          isOpen: true,
          headerContent: 'תשלום באשראי',
        },
        currentContact: <Credit orderDetails={orderDetails} updateStatus={this.updateStatus} />,
      }));
    }).catch(err => this.setState(({
      Modal: {
        isOpen: true,
        headerContent: 'בעיה טכנית',
      },
      currentContact: <GeneralMessage toggleModal={this.toggleModal} text="אנא פנה לשירות הטכני" buttonText="אישור" />,
    })));
  }

  render() {
    const { Modal, Modal: { isOpen }, currentContact } = this.state;
    return (
      <React.Fragment>
        <Buttons>
          <Button width="200px" fontSize="26px" height="80px" margin="0 0 0 0" borderRadius="6px" background="#C2DB39" handleButtonClick={this.OpenDrawer}>
            {<FolderOpen size="25" />}
            {<ButtonText>פתיחת מגירה</ButtonText>}
            {' '}
          </Button>

          <Button width="200px" fontSize="26px" height="80px" margin="0 20px 0 0" borderRadius="6px" background="#C2DB39" handleButtonClick={this.SaveOrder}>
            {<Save size="25" />}
            {<ButtonText>שמירה</ButtonText>}
            {' '}
          </Button>
          <Button width="200px" height="80px" fontSize="26px" margin="0 20px 0 0" borderRadius="6px" handleButtonClick={this.cashClick} background="#C2DB39">
            {<MoneyBillAlt size="25" />}
            {' '}
            <ButtonText>מזומן</ButtonText>
          </Button>
          <Button width="200px" height="80px" fontSize="26px" margin="0 20px 0 0" borderRadius="6px" background="#C2DB39" handleButtonClick={this.creditClick}>
            {<CreditCard size="25" />}
            {' '}
            <ButtonText>אשראי</ButtonText>
          </Button>
        </Buttons>
        { isOpen
        && (
        <GlobalModal
          Modal={Modal}
          toggleModal={this.toggleModal}
        >
          {currentContact}
        </GlobalModal>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  orderDetails: state.orderDetails,
  cleanOrder: state.cleanOrder,
});

export default connect(mapStateToProps, { clean: cleanOrder })(BottomButtons);
