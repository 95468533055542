import React from 'react';
import styled from 'styled-components';
import Button from '../shared/Button';

const Buttons = styled.div`
display: flex;
flex-flow: row wrap;
width:165px;
`;
const ModalButton = styled.div`
max-width: calc(33.333%);
text-align: center;
flex: 1 0 calc(33.333%);
`;

type Props = {
  updateValue : ()=>void,
};

const KeyBoardButtons = (props: Props) => {
  const {
    updateValue,
  } = props;
  return (
    <Buttons>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="9"> 9</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="8"> 8</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="7"> 7</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="6">6</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="5"> 5</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="4"> 4</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="3"> 3</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="2"> 2</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="1"> 1</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="remove"> מחק</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="0"> 0</Button>
      </ModalButton>
      <ModalButton>
        <Button handleButtonClick={e => updateValue(e)} value="."> .</Button>
      </ModalButton>
    </Buttons>
  );
};
export default KeyBoardButtons;
