import React from 'react';
import styled from 'styled-components';

const CategoryWrapper = styled.li`
    background-color: #fff;
    margin: 6px 5px;
    cursor: pointer;
    width: 100%;
    padding: 10px 0;
    cursor: pointer;
    box-shadow: 0 -2px 10px #c2db3963;
    text-align: center;
`;
type Props = {
  category: any,
  handleCategoryClick: () => any,
};

const Category = (props: Props) => {
  const {
    handleCategoryClick, category: { id, name },
  } = props;
  return (
    <CategoryWrapper onClick={(e) => { handleCategoryClick(e, id); }}>
      {name}
    </CategoryWrapper>);
};

export default Category;
