import React, { Component } from 'react';
import styled from 'styled-components';
import { ArrowDown } from 'styled-icons/fa-solid/ArrowDown';
import { ArrowUp } from 'styled-icons/fa-solid/ArrowUp';
import { device } from '../../../../assets/css/devices';
import Button from '../../shared/Button';
import Category from './Category';
import Products from '../products/Products';

// import { getProductsByCategory } from '../../../../api/index';

const CategoriesList = styled.ul`

display: flex;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 100%;
    margin-top: 0;
    padding: 0;
`;

const CategoriesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin-right: 0;
`;
const CategoriesRelative = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    direction:ltr;
    margin-right: 10px;
`;
const ListMenu = styled.div`
display:flex;
flex:1;
flex-direction:row;
`;

const Buttons = styled.div`
display: flex;
flex-direction: column;
`;

const LeftSide = styled.div`
width:30%;
display: flex;
    flex-direction: column;
    @media ${device.desktop} {  
      flex: 1 0 10%;
      }
`;
const RightSide = styled.div`
width:66%;
display:flex;
flex-direction:column;
padding:0 10px;
`;
const Header = styled.h2`
    text-align: center;
    font-weight: 600;
    padding-right:65px;
    color: #3F79AC;

`;

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      products: [],
      prevCategory: {
        element: [],
        clicked: false,
      },

    };
    this.scrollUp = this.scrollUp.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.searchProduct = this.searchProduct.bind(this);
    this.categoryScroller = React.createRef();
  }

  componentWillMount() {
    this.getCategories(this.props);
  }

  getCategories(props) {
    const { categories } = props;
    this.setState({
      categories,
      products: categories[0].products,
    });
  }

  scrollUp() {
    this.categoryScroller.current.scrollTop -= 20;
  }

  scrollDown() {
    this.categoryScroller.current.scrollTop += 20;
  }

  handleCategoryClick(e, id) {
    const { prevCategory: { element, clicked }, categories } = this.state;
    const baseCategory = categories[0];
    let currentCategory = Array;

    if (element.length !== 0 && element === e.target) { // second click
      if (!clicked) {
        currentCategory = categories.find(category => category.id === id);
        e.target.style.backgroundColor = '#f7ecb8';
      } else {
        currentCategory = baseCategory;
        element.style.backgroundColor = '#fff';
      }
      this.setState({
        products: currentCategory.products,
        prevCategory: {
          element: e.target,
          clicked: !clicked,
        },
      });
    } else {
      currentCategory = categories.find(category => category.id === id);
      this.setState({
        products: currentCategory.products,
        prevCategory: {
          element: e.target,
          clicked: !clicked,
        },
      });
      e.target.style.backgroundColor = '#f7ecb8';
      if (element.length !== 0) { element.style.backgroundColor = '#fff'; }
    }
    return true;
  }

  searchProduct(e) {
    const result = [];
    const { categories } = this.state;

    for (let i = 0; i < categories.length; i += 1) {
      for (let j = 0; j < categories[i].products.length; j += 1) {
        if (categories[i].products[j].name.includes(e.target.value)) {
          result.push(categories[i].products[j]);
        }
      }
    }

    this.setState({ products: result });
  }

  render() {
    const { products, categories } = this.state;
    return (
      <React.Fragment>
        <LeftSide>
          <Header>קטגוריות</Header>
          <ListMenu>
            {/* <Buttons>
              <Button handleButtonClick={() => this.scrollUp()} margin="0 0 10px 0">{<ArrowUp size="24" />}</Button>
              <Button handleButtonClick={() => this.scrollDown()}>{<ArrowDown size="24" />}</Button>
            </Buttons> */}

            <CategoriesRelative>
              <CategoriesWrapper>

                <CategoriesList ref={this.categoryScroller}>
                  {categories.map(category => (
                    <Category
                      key={category.id}
                      category={category}
                      handleCategoryClick={this.handleCategoryClick}
                    />
                  ))}
                </CategoriesList>
              </CategoriesWrapper>
            </CategoriesRelative>
          </ListMenu>
        </LeftSide>
        <RightSide>
          <Products products={products} searchProduct={this.searchProduct} />
        </RightSide>

      </React.Fragment>
    );
  }
}

export default Categories;
