import React from 'react';
import styled from 'styled-components';
import Button from '../../shared/Button';

const ButtonText = styled.div`
font-size:16px;
`;
const ButtonWrapper = styled.div`
    margin-left: 40px;
`;
const Rows = styled.div`width:50%;`;
const FieldsRow = styled.div`
margin:15px 0;
display: flex;
    justify-content: space-between;
    align-items: center;
border-bottom: ${props => (props.borderBottom ? props.borderBottom : '1px solid #c5cfcf')};

`;
const TextLeft = styled.span`float:left`;
const TotalText = styled.span`font-size:20px;`;
const TotalLeftText = styled.span`float:left;font-size:20px;`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #3991db;
  color:#fff;
  font-weight:bold;
  box-shadow: 0px 0px 10px black;
  display: flex;
  flex: 1;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
`;
const Span = styled.span`
`;
const MainWrapper = styled.div`
flex:1;
display:flex;
z-index:9;
`;
type Props = {
    orderDetails: Object,
  };
const Summary = (props : Props) => {
  const {
    discountCartClick,
    orderDetails: {
      discountAmount, total, afterDiscount, discountPercent,
    },
  } = props;
  return (
    <MainWrapper>
      <Wrapper>
        <ButtonWrapper>
          <Button background="green" handleButtonClick={discountCartClick}>{<ButtonText>הנחה</ButtonText>}</Button>
        </ButtonWrapper>
        <Rows>
          <FieldsRow>
            <span>לפני מע״מ</span>
            <TextLeft>
              {parseFloat(total / 1.17).toFixed(2)}
              {' '}
₪
            </TextLeft>
          </FieldsRow>
          <FieldsRow>
            <span>מע״מ</span>
            <TextLeft>
              {' '}
              {parseFloat(total - total / 1.17).toFixed(2)}
              {' '}
₪
            </TextLeft>
          </FieldsRow>
          <FieldsRow>
            <span>סה״כ אחרי מע״מ</span>
            <TextLeft>
              {' '}
              {parseFloat(total).toFixed(2)}
              {' '}
₪
            </TextLeft>
          </FieldsRow>
          <FieldsRow>
            <span>הנחה</span>
              <TextLeft>{` ${discountAmount === '' ? 0 : parseFloat(discountAmount).toFixed(2)}  ₪ ${discountPercent && `(${parseFloat(discountPercent).toFixed(2)}%)`}`}</TextLeft>
          </FieldsRow>

          <FieldsRow borderBottom="none">
            <TotalText>לתשלום</TotalText>
            <TotalLeftText>
              {' '}
              {afterDiscount}
              {' '}
₪
            </TotalLeftText>
          </FieldsRow>
        </Rows>
      </Wrapper>
    </MainWrapper>
  );
};

export default Summary;
