
import React, { Component } from 'react';
import styled from 'styled-components';
import TextInputGroup from '../../shared/TextInputGroup';
import ModalButton from '../../shared/ModalButton';

import KeyBoardButtons from '../KeyBoardButtons';

const EditProductWrapper = styled.ul`
margin:0;
padding: 0;
`;
const Footer = styled.div`
margin: 0 auto;
display: table;
`;
const Wrapper = styled.div`
display: flex;
flex: 1;
margin-bottom: 10px;
`;
const LeftSide = styled.div`

`;
const RightSide = styled.div`
flex:2;
`;
const FullWrapper = styled.div`
display:flex;
flex-direction:column;
`;


type Props = {
  updateProduct: (product:Object) => any;
};

class AddGeneralProduct extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      product: {
        name: '',
        quantity: '',
        price: '',
        discount: 0,
        total: 0,
      },
      validationErrors: {},
      focusInput: '',
    };
    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
  }


  onChange(e) {
    const { product } = this.state;
    this.setState({
      product: {
        ...product,
        [[e.target.name]]: e.target.value,
      },
    });
  }

  onInputFocus(e) {
    this.setState(({ focusInput: e.target.name }));
  }


  onSubmit(e) {
    e.preventDefault();
    const { updateProduct } = this.props;
    const {
      product,
      product: {
        name, quantity, price,
      },
    } = this.state;
    let ErrorsFlag = false; // setState is asynchronous

    this.setState({
      validationErrors: [],
    });

    // Check For Errors
    if (Number.isNaN(Number(price))) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          price: 'אנא הזן מספר',
        },
      }));
    }

    if (name === '') {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          name: 'שדה חסר!',
        },
      }));
    }

    if (quantity === '') {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          quantity: 'שדה חסר!',
        },
      }));
    }

    if (price === '') {
      this.ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          price: 'שדה חסר!',
        },
      }));
    }
    if (ErrorsFlag) {
      this.ErrorsFlag = false;
      return;
    }
    updateProduct(product);
  }

  updateValue(e) {
    const { product, focusInput } = this.state;
    if (focusInput === '') { return; }
    const value = e.target.getAttribute('data-value');
    const oldValue = product[focusInput];
    switch (value) {
      case 'remove':
        this.setState({
          product: {
            ...product,
            [[focusInput]]: oldValue === '' ? '' : oldValue.substr(0, oldValue.length - 1),
          },
        });
        break;
      default:
        this.setState({
          product: {
            ...product,
            [[focusInput]]: oldValue === '' ? value : oldValue.concat(value),
          },
        });
        break;
    }
  }

  render() {
    const {
      product: {
        name, quantity, price,
      }, validationErrors,
    } = this.state;

    return (
      <FullWrapper>
        <Wrapper>
          <RightSide>
            <EditProductWrapper>
              <TextInputGroup
                name="name"
                text="שם פריט:"
                InputType="text"
                InputValue={name}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.name}
              />
              <TextInputGroup
                name="quantity"
                text="כמות:"
                InputType="number"
                InputValue={quantity}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.quantity}
              />
              <TextInputGroup
                name="price"
                text="מחיר:"
                InputType="text"
                InputValue={price}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.price}
              />
            </EditProductWrapper>
          </RightSide>
          <LeftSide>
            <KeyBoardButtons updateValue={e => this.updateValue(e)} />
          </LeftSide>

        </Wrapper>
        <Footer>
          <ModalButton handleButtonClick={e => this.onSubmit(e)}>שמור</ModalButton>
        </Footer>
      </FullWrapper>
    );
  }
}
export default AddGeneralProduct;
