
import React, { Component } from 'react';
import styled from 'styled-components';
import TextInputGroup from '../../shared/TextInputGroup';
import ModalButton from '../../shared/ModalButton';

import KeyBoardButtons from '../KeyBoardButtons';

const TotalWrapper = styled.ul`
margin:0;
padding:0;
`;
const Footer = styled.div`
margin: 0 auto;
display: table;
`;
const Wrapper = styled.div`
display: flex;
flex: 1;
margin-bottom:10px;
`;
const LeftSide = styled.div`
width: 12em;
`;
const RightSide = styled.div`
width:100%;
`;
const FieldsRow = styled.div`
    margin-bottom:10px;
`;
const FullWrapper = styled.div`
display:flex;
flex-direction:column;
`;
const BlueText = styled.span`text-align: right;
font-weight: 600;
color: #3f79ac;
font-size: 14px;
margin-left: 10px;`;

type Props = {
  orderDetails: Object,
  updateCart: () => void
};
class DiscountCart extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      order: {
        percent: props.orderDetails.discountPercent,
        amount: props.orderDetails.discountAmount === 0 ? '' : props.orderDetails.discountAmount,
        total: props.orderDetails.total,
        afterDiscount: Number(props.orderDetails.total - props.orderDetails.discountAmount).toFixed(2),
        discountAmountStatus: props.orderDetails.discountAmountStatus,
      },

      validationErrors: {},
      focusInput: '',
    };
    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
  }


  onChange(e) {
    const { focusInput } = this.state;
    if (focusInput === '') { return; }

    if (Number.isNaN(Number(e.target.value))) {
      return;
    }

    if (e.target.name === 'percent') {
      this.updatePercent(e.target.value);
    } else {
      this.updateAmount(e.target.value);
    }
  }


  onInputFocus(e) {
    this.setState(({ focusInput: e.target.name }));
  }

  onSubmit(e) {
    e.preventDefault();
    const { updateCart } = this.props;

    const {
      order: {
        percent, amount,
      },
      order,
    } = this.state;
    let ErrorsFlag = false; // setState is asynchronous

    this.setState({
      validationErrors: [],
    });
    // // Check For Errors
    if (Number.isNaN(Number(percent))) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          percent: 'אנא הזן מספר',
        },
      }));
    }
    if (Number.isNaN(Number(amount))) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          amount: 'אנא הזן מספר',
        },
      }));
    }
    if (ErrorsFlag) {
      ErrorsFlag = false;
      return;
    }
    updateCart(order);
  }

  updateValue(e) {
    const value = e.target.getAttribute('data-value');
    const { focusInput, order } = this.state;
    if (focusInput === '') { return; }
    const oldValue = order[focusInput].toString();

    if (focusInput === '') { return; }

    switch (value) {
      case 'remove':
        if (focusInput === 'percent') {
          this.updatePercent(oldValue.substr(0, oldValue.length - 1));
        } else {
          this.updateAmount(oldValue.substr(0, oldValue.length - 1));
        }
        break;
      default:
        if (focusInput === 'percent') {
          this.updatePercent(oldValue.concat(value));
        } else {
          this.updateAmount(oldValue.concat(value));
        }
        break;
    }
  }

  updatePercent(value) {
    if (Number.isNaN(Number(value))) { return; }
    const {
      order,
      order: {
        total,
      },
    } = this.state;
    const resAmount = value >= 100 ? 100 : value;
    this.setState({
      order: {
        ...order,
        percent: value,
        amount: ((total * resAmount) / 100).toFixed(2),
        afterDiscount: Number(total - ((total * resAmount) / 100)).toFixed(2),
        discountAmountStatus: false,
      },
    });
  }

  updateAmount(value) {
    if (Number.isNaN(Number(value))) { return; }
    const {
      order,
      order: {
        total,
      },
    } = this.state;
    const resAmount = Number(value) >= total ? total : Number(value);

    this.setState({
      order: {
        ...order,
        amount: value,
        percent: ((resAmount * 100) / total) === 0 ? '' : ((resAmount * 100) / total).toFixed(2),
        afterDiscount: Number(total - resAmount).toFixed(2),
        discountAmountStatus: true,
      },
    });
  }

  render() {
    const {
      order: {
        amount, percent, total, afterDiscount,
      }, validationErrors,
    } = this.state;

    return (
      <FullWrapper>
        <Wrapper>
          <RightSide>
            <TotalWrapper>
              <FieldsRow>
                <BlueText>סה״כ:</BlueText>
                <span>
                  {' '}
                  {total}
                </span>
              </FieldsRow>
              <TextInputGroup
                name="percent"
                text="הנחה באחוזים:"
                InputType="text"
                InputValue={percent}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.percent}
              />
              <TextInputGroup
                name="amount"
                text="הנחה בשקלים:"
                InputType="text"
                InputValue={amount}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.amount}
              />
              <FieldsRow>
                <BlueText>מחיר לאחר הנחה:</BlueText>
                <span>
                  {' '}
                  {afterDiscount}
                </span>
              </FieldsRow>
            </TotalWrapper>
          </RightSide>
          <LeftSide>
            <KeyBoardButtons updateValue={e => this.updateValue(e)} />
          </LeftSide>

        </Wrapper>
        <Footer>
          <ModalButton handleButtonClick={e => this.onSubmit(e)}>שמור</ModalButton>
        </Footer>
      </FullWrapper>

    );
  }
}
export default DiscountCart;
