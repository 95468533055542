import React, { Component } from 'react';
import { Provider } from 'react-redux';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import font from './assets/fonts/open-sans-hebrew/opensanshebrew-regular-webfont.woff';
import data from './test-data/data.json';
import store from './store';
import Cart from './ui/components/lists/cart/index';
import Categories from './ui/components/lists/categories/Categories';
import BottomButtons from './ui/components/footer/BottomButtons';
import FullScreenButton from './ui/components/shared/FullScreenButton';
import { device } from './assets/css/devices';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Open Sans Hebrew';
    src: url(${font});
  }
 html,body,#root{
    height:100%;
    overflow:hidden;
  }

  body {
    font-family: "Open Sans Hebrew",sans-serif;
    direction:rtl;
    margin-top:0;
    margin-bottom:0;
  }

#root {
    scrollbar-width: none;
}

    .jnIKWZ{
      z-index:9999;
    }
`;
const Wrapper = styled.div`
  display: flex;
  height:100%; 
  max-height: calc(100vh - 40px);
`;
const LeftSide = styled.div`
  flex: 65%;
  display:flex;
  flex-direction:row;
`;
const RightSide = styled.div`
  flex: 35%;
  display: flex;
  flex-direction: column;
`;
const MainContent = styled.div`
height:100%;

    /* @media ${device.desktop} {  
      height:620px;

      } */
`;

const Footer = styled.div`
padding: 50px 0;
display: flex;
flex-direction: row-reverse;
`;
const MainWrapper = styled.div`
  position: ${props => props.theme.position};
  top: ${props => props.theme.top};
  left: ${props => props.theme.left};
  bottom: ${props => props.theme.bottom};
  width: ${props => props.theme.width};
    display: flex;
    flex-direction: column;
    height:100%;
`;
const positionAbsolute = {
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  width: '100%',
};
const positionInitial = {
  position: 'initial',

};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResizeScreen: positionInitial,
      Resize: false,
    };
    this.handleResizeOn = this.handleResizeOn.bind(this);
    this.handleResizeOff = this.handleResizeOff.bind(this);
  }

  handleResizeOn(e) {
    e.preventDefault();
    this.setState({ Resize: true, ResizeScreen: positionAbsolute });
  }

  handleResizeOff() {
    this.setState({ Resize: false, ResizeScreen: positionInitial });
  }

  render() {
    const { categories } = data;
    const { ResizeScreen, Resize } = this.state;
    return (
      <ThemeProvider theme={ResizeScreen}>
        <Provider store={store}>
          <MainWrapper>
            <ModalProvider zIndex="9999">
              <GlobalStyle />
              <FullScreenButton
                handleResizeOn={(e) => { this.handleResizeOn(e); }}
                handleResizeOff={(e) => { this.handleResizeOff(e); }}
                Resize={Resize}
              />
              <MainContent>
                <Wrapper>
                  <LeftSide>
                    <Categories categories={categories} />
                  </LeftSide>
                  <RightSide>
                    <Cart />
                  </RightSide>
                </Wrapper>
              </MainContent>
              <Footer>
                <BottomButtons />
              </Footer>
            </ModalProvider>
          </MainWrapper>
        </Provider>
      </ThemeProvider>
    );
  }
}
export default App;
