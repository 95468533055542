import React from 'react';
import styled from 'styled-components';

const FieldsRow = styled.li`
    display:inline-block;
    width:100%;
    margin-bottom:6px;
`;

const InputField = styled.input`
background: #f9fafa none repeat scroll 0 0;
border: 1px solid #c5cfcf;
color: #000;
font-weight: bold;
margin: 5px 0 0;
padding: 7px 0;
text-indent: 10px;
float:right;
`;
const Line = styled.div`
display:flex;
flex-direction:row;
`;
const TextField = styled.span`
text-align: right;
font-weight: 600;
color: #3f79ac;
font-size: 14px;
margin-left:10px;
`;
const ErrorInvalid = styled.span`
color:red;
font-weight: 600;
    font-size: 14px;
`;

const TextInputGroup = ({
  name, text, InputType, InputValue, onChange, onFocus, error,
  FieldComponent = InputField,

}) => (
  <FieldsRow>
    <Line>
      <TextField>
        {text}
      </TextField>
      {error && <ErrorInvalid>{error}</ErrorInvalid>}
    </Line>

    <FieldComponent
      name={name}
      type={InputType}
      value={InputValue}
      onChange={onChange}
      onFocus={onFocus}
    />

  </FieldsRow>
);

export default TextInputGroup;
