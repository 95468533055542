import React from 'react';
import styled from 'styled-components';
import { CheckCircle } from 'styled-icons/fa-regular/CheckCircle';
import ModalButton from '../../shared/ModalButton';

const MessageText = styled.p`
`;
const Wrapper = styled.div`
display: flex;
flex: 1;
margin-bottom: 10px;
justify-content:center;
font-size:25px;
direction: rtl;
`;
const FullWrapper = styled.div`
display:flex;
flex-direction:column;
`;
const Footer = styled.div`
margin: 0 auto;
display: table;
`;
const SuccessMessage = ({
  toggleModal,
}) => (
  <FullWrapper>
    <Wrapper>
      <MessageText>
        {' '}
הפעולה בוצעה בהצלחה
        {' '}
        {<CheckCircle size="24" color="rgba(165,220,134)" />}
        {' '}
      </MessageText>
    </Wrapper>
    <Footer>
      <ModalButton handleButtonClick={toggleModal}>שמור</ModalButton>
    </Footer>
  </FullWrapper>
);

export default SuccessMessage;
