import React from 'react';
import styled from 'styled-components';
import { ArrowDown, ArrowUp, Edit } from 'styled-icons/fa-solid';
import { Delete } from 'styled-icons/material/Delete';

import Button from '../../../shared/Button';

const Buttons = styled.div`
display: flex;
flex-direction: column;
height:100%;
`;
const ButtonText = styled.div`
font-size:16px;
`;

type Props = {
  scrollUp: () => any,
  scrollDown: () => any,
  removeProductClick: () => any,
  editProductClick: () => any,
};

const SideButtons = (props: Props) => {
  const {
    scrollUp, scrollDown, removeProductClick, editProductClick,
  } = props;
  return (
    <Buttons>
      {/* <Button handleButtonClick={scrollUp} margin="0 0 10px 0">{<ArrowUp size="24" />}</Button>
      <Button handleButtonClick={scrollDown} margin="0 0 10px 0">{<ArrowDown size="24" />}</Button> */}
      <Button background="coral" handleButtonClick={editProductClick} margin="0 0 10px 0">{<Edit size="24" />}</Button>
      <Button background="red" handleButtonClick={removeProductClick} margin="0 0 10px 0">{<Delete size="24" />}</Button>
    </Buttons>
  );
};


export default SideButtons;
