import {
  GET_PRODUCTS, ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_PRODUCT, CALC_CART, CLEAN_ORDER, UPDATE_ORDER,
} from './types';

export const getProducts = () => ({
  type: GET_PRODUCTS,
});
export const removeProduct = id => ({
  type: REMOVE_PRODUCT,
  payload: id,
});
export const addProduct = product => ({
  type: ADD_PRODUCT,
  payload: product,
});
export const updateProduct = product => ({
  type: UPDATE_PRODUCT,
  payload: product,
});
export const updateCartTotal = () => ({
  type: CALC_CART,
});
export const cleanOrder = () => ({
  type: CLEAN_ORDER,
});
export const updateOrder = orderDetails => ({
  type: UPDATE_ORDER,
  payload: orderDetails,
});
