import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getProducts, removeProduct, updateProduct, updateCartTotal, updateOrder,
} from '../../../../actions/productActions';
import CartProducts from './CartProductsList';
import SideButtons from './cartButtons/SideButtons';
import GlobalModal from '../../modal/index';
import EditProduct from '../../modal/content/EditProduct';
import DiscountCart from '../../modal/content/DiscountCart';
import RemoveProduct from '../../modal/content/RemoveProduct';
import GeneralMessage from '../../modal/content/GeneralMessage';

import Summary from './Summary';

const Wrapper = styled.div`
  display: flex;
  margin-bottom:2px;
  height: 100%;

`;
const LeftSideButtom = styled.div`
  height:100%;
  display:flex;
  flex-direction:row;
`;
const RightSideButtom = styled.div`
  /* flex: 1 0 85%;
  margin-right:10px; */
  width:100%;
  position:relative;
`;
const Header = styled.h2`
    text-align: center;
    font-weight: 600;
    color: #3F79AC;
`;
class Cart extends Component {
  constructor(props) {
    super(props);

    this.cartProductClick = this.cartProductClick.bind(this);
    this.removeProductClick = this.removeProductClick.bind(this);
    this.editProductClick = this.editProductClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.discountCartClick = this.discountCartClick.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.ConfirmDeleteProduct = this.ConfirmDeleteProduct.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
    this.scrollDown = this.scrollDown.bind(this);

    this.state = {
      currentProduct: null,
      Modal: {
        isOpen: false,
        headerContent: '',
        product: {},
      },
      prevProduct: {
        element: [],
        backgroundColor: '',
        clicked: false,
      },
    };
    this.ProductLingth = this.props.cartProducts.length;
    this.cartProductsScroller = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.ProductLingth !== prevProps.cartProducts.length) {
      this.cartProductsScroller.current.scrollTop += 80;
      this.ProductLingth = prevProps.cartProducts.length;
    }
  }


  ConfirmDeleteProduct() {
    const { currentProduct: { uniqueId } } = this.state;
    const { removeProduct, updateCartTotal } = this.props;
    removeProduct(uniqueId);
    this.setState(({ currentProduct: null }));
    updateCartTotal();
    this.setState({ Modal: { isOpen: false } });
  }

  removeProductClick() {
    const { currentProduct } = this.state;
    if (currentProduct === null) {
      this.setState(({
        Modal: {
          isOpen: true,
          headerContent: 'לא נבחר מוצר',
        },
        currentContact: <GeneralMessage toggleModal={this.toggleModal} text="אנא בחר מוצר לפעולה" buttonText="אישור" />,
      }));
      return;
    }

    this.setState(({
      Modal: {
        isOpen: true,
        headerContent: `מחיקת מוצר - ${currentProduct.name}`,
      },
      currentContact: <RemoveProduct ConfirmDeleteProduct={this.ConfirmDeleteProduct} toggleModal={this.toggleModal} />,
    }));
  }

  discountCartClick() {
    const {
      orderDetails, orderDetails: { total },
    } = this.props;
    if (total === 0) { return; }
    this.setState(({
      Modal: {
        isOpen: true,
        headerContent: 'הנחה כללית',
      },
      currentContact: <DiscountCart orderDetails={orderDetails} updateCart={this.updateCart} />,
    }));
  }

  updateCart(order) {
    const { updateOrder } = this.props;
    updateOrder(order);
    this.setState({ Modal: { isOpen: false } });
  }

  editProductClick() {
    const { currentProduct } = this.state;
    const { updateCartTotal } = this.props;
    // console.log(currentProduct)
    if (currentProduct === null) {
      this.setState(({
        Modal: {
          isOpen: true,
          headerContent: 'לא נבחר מוצר',
        },
        currentContact: <GeneralMessage toggleModal={this.toggleModal} text="אנא בחר מוצר לפעולה" buttonText="אישור" />,
      }));
      return;
    }
    this.setState(({
      Modal: {
        isOpen: true,
        headerContent: 'עריכת מוצר',
        product: currentProduct,
      },
      currentContact: <EditProduct product={currentProduct} updateProduct={this.updateProduct} />,
    }));
    updateCartTotal();
  }

  toggleModal() {
    this.setState(prevState => ({ Modal: { isOpen: prevState.isOpen } }));
  }

  cartProductClick(e, product) {
    const { prevProduct: { element, backgroundColor, clicked } } = this.state;
    // const _color = backgroundColor;
    // const parentColor = e.target.style.backgroundColor;
    const currentBackground = e.target.style.backgroundColor;
    if (element.length !== 0 && element === e.target) {
      if (clicked) {
        element.style.backgroundColor = backgroundColor;
        this.setState(({
          currentProduct: null,
          prevProduct: {
            ...this.state.prevProduct,
            element: e.target,
            clicked: !clicked,
          },
        }));
      } else {
        e.target.style.backgroundColor = '#f7ecb8';
        this.setState(({
          currentProduct: product,
          prevProduct: {
            ...this.state.prevProduct,
            element: e.target,
            clicked: !clicked,
          },
        }));
      }
    } else {
      if (element.length !== 0) {
        element.style.backgroundColor = backgroundColor;
      }
      e.target.style.backgroundColor = '#f7ecb8';

      this.setState(({
        currentProduct: product,
        prevProduct: {
          element: e.target,
          backgroundColor: currentBackground,
          clicked: true,
        },
      }));
    }
  }

  updateProduct(product) {
    const { updateProduct, updateCartTotal } = this.props;
    this.setState(({
      currentProduct: product,
      Modal: {
        isOpen: false,
        headerContent: 'עריכת מוצר',
        product: {},
      },
    }));
    updateProduct(product);
    updateCartTotal();
  }

  scrollUp() {
    this.cartProductsScroller.current.scrollTop -= 40;
  }

  scrollDown() {
    this.cartProductsScroller.current.scrollTop += 40;
  }

  render() {
    const {
      cartProductsScroller, currentContact, Modal, Modal: { isOpen },
    } = this.state;
    const { cartProducts, orderDetails } = this.props;
    return (
      <React.Fragment>
        <Header>סיכום הזמנה</Header>
        <Wrapper>
          <LeftSideButtom>
            <SideButtons
              removeProductClick={this.removeProductClick}
              editProductClick={this.editProductClick}
              scrollUp={this.scrollUp}
              scrollDown={this.scrollDown}
              cartProductsScroller={cartProductsScroller}
            />
          </LeftSideButtom>
          <RightSideButtom>
            <CartProducts
              cartProductsScroller={this.cartProductsScroller}
              cartProductClick={this.cartProductClick}
              cartProducts={cartProducts}
            />
          </RightSideButtom>
        </Wrapper>
        <Summary orderDetails={orderDetails} discountCartClick={this.discountCartClick} />

        {isOpen
        && (
        <GlobalModal
          Modal={Modal}
          updateProduct={this.updateProduct}
          toggleModal={this.toggleModal}
        >
          {currentContact}
        </GlobalModal>
        )}
      </React.Fragment>

    );
  }
}

const mapStateToProps = state => ({
  cartProducts: state.cartProducts,
  updateProduct: state.updateProduct,
  removeProduct: state.removeProduct,
  orderDetails: state.orderDetails,
  updateCartTotal: state.updateCartTotal,
  updateOrder: state.updateOrder,
});

export default connect(mapStateToProps, {
  getProducts, removeProduct, updateProduct, updateCartTotal, updateOrder,
})(Cart);
