import {
  GET_PRODUCTS, ADD_PRODUCT, REMOVE_PRODUCT, UPDATE_PRODUCT, CALC_CART, CLEAN_ORDER, UPDATE_ORDER,
} from '../actions/types';

export default function (state, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
      };
    case ADD_PRODUCT: {
      return {
        ...state,
        cartProducts: [...state.cartProducts, action.payload],
      };
    }

    case REMOVE_PRODUCT:
      return {
        ...state,
        cartProducts: state.cartProducts.filter(cartProduct => cartProduct.uniqueId !== action.payload),
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        cartProducts: state.cartProducts.map(cartProduct => (cartProduct.uniqueId === action.payload.uniqueId
          ? (cartProduct = action.payload)
          : cartProduct
        )),
      };
    case CALC_CART: {
      const { discountAmountStatus, discountPercent } = state.orderDetails;
      const sum = state.cartProducts.reduce((acc, pilot) => acc + Number(pilot.total), 0);
      if (discountAmountStatus) {
        return {
          ...state,
          orderDetails: {
            ...state.orderDetails,
            amount: Number(state.orderDetails.amount).toFixed(2),
            total: sum.toFixed(2),
            afterDiscount: sum - state.orderDetails.discountAmount <= 0 ? 0 : (sum - state.orderDetails.discountAmount).toFixed(2),
            discountPercent: ((state.orderDetails.discountAmount * 100) / sum).toFixed(2),
          }
          ,
        };
      }
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          total: Number(sum).toFixed(2),
          afterDiscount: (sum - (sum * Number(discountPercent)) / 100).toFixed(2),
          discountAmount: ((sum * Number(discountPercent)) / 100).toFixed(2),
        }
        ,
      };
    }

    case CLEAN_ORDER:
      return {
        cartProducts: [],
        cartProduct: { },
        orderDetails: {
          discountPercent: '',
          discountAmount: 0,
          total: 0,
        },
      };

    case UPDATE_ORDER:
    {
      let sum;
      if (action.payload.discountAmountStatus) {
        sum = action.payload.afterDiscount <= 0 ? 0 : Number(action.payload.afterDiscount);
      } else {
        sum = Number(action.payload.total) - ((action.payload.total * Number(action.payload.percent)) / 100);
      }
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          discountAmountStatus: action.payload.discountAmountStatus,
          discountPercent: action.payload.percent,
          afterDiscount: Number(sum).toFixed(2),
          discountAmount: action.payload.amount,
        },
      };
    }
    default:
      return state;
  }
}
