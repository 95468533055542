const size = {
  mobileS: '360',
  mobile: '575.98px',
  mobileL: '767.98px',
  tablet: '991.98px',
  desktop: '1025px',
  desktopL: '1700px',
};

export const device = {
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};
