import React from 'react';
import API from './api/API.js';

class TestConnection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMessage: '',
      closeMessage: '',
    };
  }

  close = () => {
    const myApi = new API();
    myApi.createEntity({ name: 'Close' });
    myApi.endpoints.Close.Close({})
      .then(({ res }) => this.setState({ closeMessage: 'Close Working' }))
      .catch(err => this.setState({ closeMessage: 'Close Error' }));
  }

  componentWillMount = () => {
    const myApi = new API();
    myApi.createEntity({ name: 'Open' });
    myApi.endpoints.Open.Open({
    }).then((response) => {
      this.setState({ openMessage: 'Open Working' });
      this.close();
    }).catch(err => this.setState({ openMessage: 'Open Error' }));
  }

  render() {
    const { openMessage, closeMessage } = this.state;

    return (
      <div>
        <h1>{openMessage}</h1>
        <h1>{closeMessage}</h1>
      </div>


    );
  }
}
export default TestConnection;
