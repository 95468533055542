/* products types */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

/* cart types */
export const CALC_CART = 'CALC_CART';
export const CLEAN_ORDER = 'CLEAN_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
