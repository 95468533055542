import React from 'react';
import styled from 'styled-components';

const GlobalButton = styled.button`
    width: ${props => (props.width ? props.width : '55px')};
    height: ${props => (props.height ? props.height : '55px')};
    margin : ${props => (props.margin ? props.margin : '0')};
    background : ${props => (props.background ? props.background : '#3991db')};
    cursor:pointer;
    color:#fff;
    border:none;
    font-size : ${props => (props.fontSize ? props.fontSize : '16px')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '')};
`;

const Button = ({
  handleButtonClick, width, margin, children, value, background, height, fontSize, borderRadius, FieldComponent = GlobalButton,
}) => (
  <FieldComponent fontSize={fontSize} height={height} width={width} borderRadius={borderRadius} background={background} margin={margin} onClick={(e) => { handleButtonClick(e); }} data-value={value}>
    {children}
  </FieldComponent>
);
export default Button;
