import React from 'react';
import styled, { withTheme } from 'styled-components';

import { ArrowUpRight } from 'styled-icons/feather/ArrowUpRight';
import { X } from 'styled-icons/feather/X';
import Button from './Button';

const FullScreenWrapper = styled.div`

position:absolute;

`;
type Props = {
  Resize: any,
  handleResizeOn: () => any,
  handleResizeOff: () => any,
};
const FullScreenButton = (props: Props) => {
  const {
    handleResizeOn,
    handleResizeOff,
    Resize,
  } = props;
  return (
    <FullScreenWrapper>
      { Resize ? (
        <Button handleButtonClick={(e) => { handleResizeOff(e); }}>{<X size="24" />}</Button>
      ) : <Button handleButtonClick={(e) => { handleResizeOn(e); }}>{<ArrowUpRight size="24" />}</Button>
        }
    </FullScreenWrapper>
  );
};


export default withTheme(FullScreenButton);
