
import React, { Component } from 'react';
import styled from 'styled-components';
import TextInputGroup from '../../shared/TextInputGroup';
import ModalButton from '../../shared/ModalButton';
import KeyBoardButtons from '../KeyBoardButtons';

const TotalWrapper = styled.ul`
margin:0;
padding:0;
`;
const Footer = styled.div`
margin: 0 auto;
display: table;
`;
const Wrapper = styled.div`
display: flex;
flex: 1;
margin-bottom: 10px;
`;
const LeftSide = styled.div`
width: 12em;
`;
const RightSide = styled.div`
width:100%;
`;
const FieldsRow = styled.div`
    margin-bottom:10px;
`;
const FullWrapper = styled.div`
display:flex;
flex-direction:column;
`;
const LTR = styled.span`direction:ltr;display: inline-block;`;

type Props = {
  orderDetails: Object,
  updateStatus: () => any
};

class Cash extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      getMoney: '',
      backMoney: '',
      validationErrors: {},
      orderDetails: props.orderDetails,
    };

    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  onChange(e) {
    if (Number.isNaN(Number(e.target.value))) {
      return;
    }

    const { orderDetails: { afterDiscount } } = this.props;
    this.setState({
      getMoney: e.target.value,
      backMoney: (e.target.value - afterDiscount).toFixed(2),
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { updateStatus, orderDetails: { afterDiscount } } = this.props;
    const {
      getMoney,
    } = this.state;
    let ErrorsFlag = false; // setState is asynchronous

    this.setState({
      validationErrors: [],
    });

    // // Check For Errors
    if (parseInt(getMoney, 10) < afterDiscount) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          getMoney: 'הסכום קטן מהסכום הכללי',
        },
      }));
    }
    if (getMoney === '') {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          getMoney: 'שדה חסר!',
        },
      }));
    }
    if (ErrorsFlag) {
      ErrorsFlag = false;
      return;
    }
    updateStatus();
  }

  updateValue(e) {
    const {
      orderDetails: { afterDiscount },
    } = this.props;

    const value = e.target.getAttribute('data-value');

    const { getMoney } = this.state;

    switch (value) {
      case 'remove':
        this.setState({
          getMoney: getMoney === '' ? '' : getMoney.substr(0, getMoney.length - 1),
          backMoney: (Number(getMoney.substr(0, getMoney.length - 1)) - afterDiscount).toFixed(2),
        });
        break;
      default:
        if (Number.isNaN(Number(getMoney.concat(value)))) { return; }
        this.setState({
          getMoney: getMoney === '' ? value : getMoney.concat(value),
          backMoney: (Number(getMoney.concat(value)) - afterDiscount).toFixed(2),
        });
        break;
    }
  }

  render() {
    const {
      getMoney, backMoney, validationErrors, orderDetails: { afterDiscount },
    } = this.state;

    return (
      <FullWrapper>
        <Wrapper>

          <RightSide>
            <TotalWrapper>
              <FieldsRow>
                <span>סה״כ לתשלום:</span>
                <span>
                  {' '}
                  {afterDiscount}
                </span>
              </FieldsRow>
              <TextInputGroup
                name="getMoney"
                text="מזומן שקיבלתי:"
                InputType="text"
                InputValue={getMoney}
                onChange={e => this.onChange(e)}
                error={validationErrors.getMoney}
              />
              <FieldsRow>
                <span>עודף:</span>
                <LTR>
                  {' '}
                  {backMoney}
                </LTR>
              </FieldsRow>
            </TotalWrapper>
          </RightSide>
          <LeftSide>
            <KeyBoardButtons updateValue={e => this.updateValue(e)} />
          </LeftSide>
        </Wrapper>
        <Footer>
          <ModalButton handleButtonClick={e => this.onSubmit(e)}>שמור</ModalButton>
        </Footer>
      </FullWrapper>
    );
  }
}
export default Cash;
