import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from 'styled-react-modal';
import closeImg from './fancybox_sprite@2x.png';

const StyledModal = Modal.styled`
    width: 60%;
    display: flex;
    padding:20px;
    flex-direction: column;
    position:relative;
    color: rgb(68, 68, 68);
    text-shadow: none;
    background: rgb(249, 249, 249);
    border-radius: 4px;
    max-width:600px;
`;
const InnerModal = styled.div`
background-color: rgb(255, 255, 255);
padding:10px 20px;
`;
const CloseModal = styled.a`
position: absolute;
top: -18px;
right: -18px;
width: 36px;
height: 36px;
cursor: pointer;
    background-image: url(${closeImg});
    background-size: 44px 152px;
`;
const Header = styled.div`

`;
const HeaderText = styled.h2`
font-size: 25px ;
    font-weight: 600 ;
    border-bottom: 1px solid #cccccc;
    color: #c2db39 ;
    padding: 0;
    margin: 0 0 15px 0;
    `;
    type Props = {
      Modal: Object,
      children:any,
      toggleModal : ()=>void,
    };
class GlobalModal extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.Modal.isOpen,
      headerContent: props.Modal.headerContent,
    };
  }

  render() {
    const { isOpen, headerContent } = this.state;
    const { children, toggleModal } = this.props;
    return (
      <StyledModal isOpen={isOpen}>
        <InnerModal>
          <CloseModal onClick={() => toggleModal()} />
          <Header>
            <HeaderText>{headerContent}</HeaderText>
          </Header>
          {children}
        </InnerModal>
      </StyledModal>
    );
  }
}
export default GlobalModal;
