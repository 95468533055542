import React from 'react';
import styled from 'styled-components';

const ProductWrapper = styled.li`
    list-style: none;
    flex: 0 0 31%;
    display: flex;
    flex-direction: column;
    margin:4px;
    text-align:center;
    cursor:pointer;
    box-shadow: 0 -2px 10px #c2db3963;
    border-radius: 5px;
    height: 100px;
`;

const ProductImage = styled.img`
    margin:0 auto;
    width: 60px;
    margin-top: 10px;
`;

const ProductText = styled.span`
padding-bottom:10px;
`;

type Props = {
  product: any,
  onProductClick: () => any,
};

const Product = (props: Props) => {
  const {
    product,
    product: {
      name, pic,
    },
    onProductClick,
  } = props;
  return (
    <ProductWrapper onClick={() => onProductClick(product)}>
      <ProductImage alt={name} src={pic} />
      <ProductText>{name}</ProductText>
    </ProductWrapper>);
};

export default Product;
