import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../assets/css/devices';
// import CartProduct from './CartProduct';

const CartTable = styled.div`
  margin-right:5px;
`;
const CartTableHeader = styled.div`
  background-color: #3991db;
  display: flex;
    justify-content: center;
    align-items: center;
  flex-direction: row-reverse;
  
  color: #fff;
  font-weight: bold;
  height: 55px;
   
`;
const CartTableCell = styled.div`
  flex: 1;
  padding: 0.2rem 0.4em;
  font-size: 18px;
  font-weight:bold;
  pointer-events: none;
`;
const CartTableRow = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${props => (props.background)};
/* padding:14px 0 ; */
height: 55px;
    justify-content: center;
    align-items: center;
`;
const CartTableBody = styled.div`
overflow:auto;
direction:ltr;
text-align: right;
max-height: calc(100vh - 520px);
`;

type Props = {
  product: any,
  background:any,
  cartProducts : any,
  cartProductsScroller: () => any,
  cartProductClick: () => any,
};

const cartProductsList = (props:Props) => {
  const {
    cartProducts,
    cartProductsScroller,
    cartProductClick,
  } = props;
  const rows = cartProducts.map((product, i) => (

    <CartProduct
      background={i % 2 === 0 ? '#f2f2f2' : '#fff'}
      key={product.uniqueId}
      product={product}
      cartProductClick={cartProductClick}
    />
  ));

  return (
    <CartTable>
      <CartTableHeader>
        <CartTableCell>סכום</CartTableCell>
        <CartTableCell>הנחה</CartTableCell>
        <CartTableCell>מחיר</CartTableCell>
        <CartTableCell>כמות</CartTableCell>
        <CartTableCell>פריט</CartTableCell>
      </CartTableHeader>
      <CartTableBody ref={cartProductsScroller}>
        {rows}
      </CartTableBody>
    </CartTable>
  );
};

function CartProduct(props:Props) {
  const {
    product, product: {
      name, price, quantity, discount, total, discountPercent,
    },
    background,
    cartProductClick,
  } = props;

  return (
    <CartTableRow onClick={e => cartProductClick(e, product)} background={background}>
      <CartTableCell>{total}</CartTableCell>
      <CartTableCell>
        {Number(discountPercent) !== 0 ? `${discountPercent}%` : '' }
      </CartTableCell>
      <CartTableCell>{price}</CartTableCell>
      <CartTableCell>{quantity}</CartTableCell>
      <CartTableCell>{name}</CartTableCell>
    </CartTableRow>
  );
}

export default cartProductsList;
