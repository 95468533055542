import React from 'react';
import styled from 'styled-components';

const ButtonStyle = styled.button`
padding: 5px 50px;
margin: 0;
background: #3991db;
cursor: pointer;
color: #fff;
font-size: 16px;
border-radius: 4px;
font-size: 20px;
font-weight: 300;
`;
type Props = {
  children: any,
  handleButtonClick: () => any,
};
const ModalButton = (props: Props) => {
  const {
    handleButtonClick, children,
  } = props;
  return (
    <ButtonStyle onClick={handleButtonClick}>
      {children}
    </ButtonStyle>
  );
};

export default ModalButton;
