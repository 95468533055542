
import React, { Component } from 'react';
import styled from 'styled-components';
import TextInputGroup from '../../shared/TextInputGroup';
import ModalButton from '../../shared/ModalButton';

import KeyBoardButtons from '../KeyBoardButtons';

const EditProductWrapper = styled.ul`
margin:0;
padding: 0;
`;

const Wrapper = styled.div`
display: flex;
flex: 1;
margin-bottom: 10px;
`;
const FullWrapper = styled.div`
display:flex;
flex-direction:column;
`;
const Footer = styled.div`
margin: 0 auto;
display: table;
`;
const LeftSide = styled.div`
width: 12em;
`;
const RightSide = styled.div`
width:100%;
`;
const FieldsRow = styled.div`
    margin-bottom:10px;
`;
const BlueText = styled.span`text-align: right;
font-weight: 600;
color: #3f79ac;
font-size: 14px;
margin-left: 10px;`;

type Props = {
  product: () => void,
  updateProduct: () => void
};

class EditProduct extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      product: {
        name: props.product.name,
        quantity: props.product.quantity,
        price: props.product.price,
        discount: props.product.discount === 0 ? '' : props.product.discount,
        uniqueId: props.product.uniqueId,
        total: props.product.total,
        discountPercent: props.product.discountPercent === 0 ? '' : props.product.discountPercent,
      },
      focusInput: '',
      validationErrors: {},

    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  onChange(e) {
    if (e.target.name !== 'name' && Number.isNaN(Number(e.target.value))) {
      return;
    }
    if (e.target.name === 'discountPercent') {
      const updatedAmount = e.target.value >= 100 ? 100 : e.target.value;
      this.updateDiscountPercent(updatedAmount);
    } else if (e.target.name === 'discount') {
      this.updateDiscount(e.target.value);
    } else if (e.target.name === 'quantity') {
      this.updateQuantity(e.target.value);
    } else if (e.target.name === 'name') {
      this.updateName(e.target.value);
    } else {
      this.updatePrice(e.target.value);
    }
  }

  onInputFocus(e) {
    this.setState(({ focusInput: e.target.name }));
  }


  onSubmit(e) {
    e.preventDefault();
    const {
      product,
      product: {
        quantity, discount, discountPercent, name, price,
      },
    } = this.state;
    const { updateProduct } = this.props;

    let ErrorsFlag = false; // setState is asynchronous
    this.setState({
      validationErrors: [],
    });

    // Check For Errors
    if (Number.isNaN(Number(price))) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          price: 'אנא הזן מחיר',
        },
      }));
    }
    if (Number.isNaN(Number(quantity))) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          quantity: 'אנא הזן מספר',
        },
      }));
    }
    if (Number.isNaN(Number(discountPercent))) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          price: 'אנא הזן מספר',
        },
      }));
    }
    if (Number.isNaN(Number(discount))) {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          price: 'אנא הזן מספר',
        },
      }));
    }
    if (price === '') {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          price: 'שדה חסר!',
        },
      }));
    }
    if (quantity === '') {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          quantity: 'שדה חסר!',
        },
      }));
    }
    if (name === '') {
      ErrorsFlag = true;
      this.setState(prevState => ({
        validationErrors: {
          ...prevState.validationErrors,
          name: 'שדה חסר!',
        },
      }));
    }
    if (ErrorsFlag) {
      ErrorsFlag = false;
      return;
    }

    updateProduct(product);
  }

  updateValue(e) {
    const { product, focusInput } = this.state;
    if (focusInput === '') { return; }
    const value = e.target.getAttribute('data-value');
    const oldValue = product[focusInput].toString();
    let currentValue;
    switch (value) {
      case 'remove':
        currentValue = oldValue.substr(0, oldValue.length - 1);
        if (focusInput === 'discountPercent') {
          this.updateDiscountPercent(currentValue);
        } else if (focusInput === 'discount') {
          this.updateDiscount(currentValue);
        } else if (focusInput === 'quantity') {
          this.updateQuantity(currentValue);
        } else if (focusInput === 'name') {
          this.updateName(currentValue);
        } else {
          this.updatePrice(currentValue);
        }
        break;
      default:
        currentValue = oldValue.concat(value);
        if (focusInput === 'discountPercent') {
          this.updateDiscountPercent(currentValue);
        } else if (focusInput === 'discount') {
          this.updateDiscount(currentValue);
        } else if (focusInput === 'quantity') {
          this.updateQuantity(currentValue);
        } else if (focusInput === 'name') {
          this.updateName(currentValue);
        } else {
          this.updatePrice(currentValue);
        }
        break;
    }
  }

  updateName(value) {
    const { product } = this.state;
    this.setState({
      product: {
        ...product,
        name: value,
      },
    });
  }

  updatePrice(value) {
    const { product, product: { quantity, discountPercent } } = this.state;
    const currentTotal = (Number(value) * Number(quantity));
    const updatedValue = currentTotal - (currentTotal * Number(discountPercent) / 100);
    this.setState({
      product: {
        ...product,
        price: value,
        total: Number(updatedValue) <= 0 ? 0 : Number(updatedValue).toFixed(2),
        discount: Number(value * Number(discountPercent) / 100).toFixed(2),
      },
    });
  }

  updateDiscountPercent(value) {
    const { product, product: { quantity, price } } = this.state;
    const currentTotal = Number(price) * Number(quantity);
    const updatedValue = Number(price) * Number(value) / 100; // Per Unit
    const updatedValueTotal = currentTotal * Number(value) / 100;
    this.setState({
      product: {
        ...product,
        discount: updatedValue === 0 ? 0 : updatedValue.toFixed(2),
        total: (currentTotal - updatedValueTotal) <= 0
          ? 0 : (currentTotal - updatedValueTotal).toFixed(2),
        discountPercent: value,
      },
    });
  }

  updateDiscount(value) {
    const { product, product: { quantity, price } } = this.state;
    const currentTotal = Number(price) * Number(quantity);
    let updatedValue = currentTotal - Number(value) * Number(quantity);
    updatedValue = Number((updatedValue) <= 0 ? 0 : updatedValue);
    const discountPrecentValue = (currentTotal - updatedValue) * 100 / currentTotal;
    this.setState({
      product: {
        ...product,
        discount: value,
        total: updatedValue.toFixed(2),
        discountPercent: Number(discountPrecentValue === 0 ? '' : discountPrecentValue).toFixed(2),
      },
    });
  }

  updateQuantity(value) {
    const { product, product: { price, discountPercent } } = this.state;// price 10 quantity 1 discoung 50% =
    const currentTotal = (Number(price) * Number(value));
    const updatedValue = currentTotal - (currentTotal * Number(discountPercent) / 100);
    this.setState({
      product: {
        ...product,
        // discount: Number(updatedValue * discountPercent / 100).toFixed(2),
        total: Number(updatedValue) <= 0 ? 0 : Number(updatedValue).toFixed(2),
        quantity: value,
      },
    });
  }

  render() {
    const {
      product: {
        name, quantity, price, discount, total, discountPercent,
      }, validationErrors,
    } = this.state;
    return (
      <FullWrapper>
        <Wrapper>

          <RightSide>
            <EditProductWrapper>
              <TextInputGroup
                name="name"
                text="שם פריט:"
                InputType="text"
                InputValue={name}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.name}
              />
              <TextInputGroup
                name="price"
                text="מחיר:"
                InputType="text"
                InputValue={price}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.price}
              />
              <TextInputGroup
                name="quantity"
                text="כמות:"
                InputType="text"
                InputValue={quantity}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.quantity}
              />
              <TextInputGroup
                name="discountPercent"
                text="הנחה באחוזים:"
                InputType="text"
                InputValue={discountPercent}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.discountPercent}
              />
              <TextInputGroup
                name="discount"
                text="הנחה בשקלים:"
                InputType="text"
                InputValue={discount}
                onFocus={e => this.onInputFocus(e)}
                onChange={e => this.onChange(e)}
                error={validationErrors.discount}
              />
              <FieldsRow>
                <BlueText>סכום:</BlueText>
                <span>
                  {' '}
                  {total}
                </span>
              </FieldsRow>
            </EditProductWrapper>
          </RightSide>
          <LeftSide>
            <KeyBoardButtons updateValue={e => this.updateValue(e)} />
          </LeftSide>
        </Wrapper>
        <Footer>
          <ModalButton handleButtonClick={e => this.onSubmit(e)}>שמור</ModalButton>
        </Footer>
      </FullWrapper>
    );
  }
}
export default EditProduct;
