import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import styled from 'styled-components';
import { ArrowDown } from 'styled-icons/fa-solid/ArrowDown';
import { ArrowUp } from 'styled-icons/fa-solid/ArrowUp';
import * as productActions from '../../../../actions/productActions';
import Button from '../../shared/Button';
import Product from './Product';
import AddGeneralProduct from '../../modal/content/AddGeneralProduct';
import GlobalModal from '../../modal/index';

const ProductsList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 100%;
    margin-top:0;
    padding:0;
`;
const ProductListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin-right:0;
`;

const ListMenu = styled.div`
    display:flex;
    flex-direction:row;
    flex:2;
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
`;

const BottomButtons = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
    align-items: flex-end;
  
`;
const Header = styled.h2`
    text-align: center;
    font-weight: 600;
    color: #3F79AC;
`;
const SearchForm = styled.input`
text-align: right;
    background: #f9fafa none repeat scroll 0 0;
    border: 1px solid #c5cfcf;
    color: #8d8e8e;
    float: right;
    font-family: open sans hebrew;
    font-size: 28px;
    height: 75px;
    line-height: 76px;
    padding: 0;
    text-indent: 10px;
    margin-bottom:10px;
`;
const ProductsWrapper = styled.div`
display:flex;
flex-direction:column;
flex:1;
position:relative;
direction:ltr;
margin-right: 10px;
`;
const CenterButtons = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`;
const EmptyButton = styled.div`width:20px;`;
const NotFoundMessage = styled.div`
    margin: 0 auto;
    font-size: 20px;
    padding: 20px;
`;
type Props = {
  products: any,
  searchProduct: (e:any) => any,
  updateCartTotal : ()=>any,
  addProduct : ()=>any,
};

type State = {
  Modal: any,
  scrollUp:()=>any,

}
class products extends Component<Props, State> {
  constructor(props:any) {
    super(props);
    this.state = {
      Modal: {
        isOpen: false,
        headerContent: 'מוצר כללי',
        product: {},
      },
    };

    this.scrollUp = this.scrollUp.bind(this);
    this.scrollDown = this.scrollDown.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
    this.addGeneralProductClick = this.addGeneralProductClick.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.ProductScroller = React.createRef();
  }

  onProductClick(product) {
    const { updateCartTotal, addProduct } = this.props;

    addProduct({
      ...product, quantity: 1, discount: '', discountPercent: 0, total: product.price, uniqueId: v4(),
    });
    updateCartTotal();
  }

  scrollUp() {
    this.ProductScroller.current.scrollTop -= 20;
  }

  scrollDown() {
    this.ProductScroller.current.scrollTop += 20;
  }

  toggleModal() {
    this.setState(prevState => ({ Modal: { ...prevState.Modal, isOpen: prevState.isOpen } }));
  }

  addGeneralProductClick() {
    this.setState(prevState => ({ Modal: { ...prevState.Modal, isOpen: !prevState.isOpen } }));
  }

  updateProduct(product) {
    const { addProduct, updateCartTotal } = this.props;

    addProduct({
      ...product, discount: '', discountPercent: 0, total: (product.quantity * product.price).toFixed(2), updated: true, uniqueId: v4(),
    });

    this.setState(prevState => ({ Modal: { ...prevState.Modal, isOpen: prevState.isOpen } }));
    updateCartTotal();
  }

  render() {
    const { searchProduct, products } = this.props;
    const { Modal, Modal: { isOpen } } = this.state;
    return (
      <React.Fragment>
        <Header>מוצרים</Header>
        <SearchForm onChange={e => searchProduct(e)} placeholder="חיפוש מוצרים" />
        <ListMenu>
          {/* <Buttons>
            <Button handleButtonClick={() => this.scrollUp()} margin="0 0 10px 0">{<ArrowUp size="24" />}</Button>
            <Button handleButtonClick={() => this.scrollDown()}>{<ArrowDown size="24" />}</Button>
          </Buttons> */}
          <ProductsWrapper>
            <ProductListWrapper>
              <ProductsList ref={this.ProductScroller}>
                {products.length !== 0 ? products.map(product => (
                  <Product key={product.id} product={product} onProductClick={element => this.onProductClick(element)} />
                )) : (<NotFoundMessage>לא נמצאו מוצרים</NotFoundMessage>)
                }
              </ProductsList>
            </ProductListWrapper>
          </ProductsWrapper>

        </ListMenu>
        <BottomButtons>
          <CenterButtons>
            <Button width="110px" margin="0;" handleButtonClick={() => this.addGeneralProductClick()}>מוצר כללי</Button>
            <EmptyButton />
            <Button width="110px" margin="0">קישור ללקוח</Button>
          </CenterButtons>
        </BottomButtons>
        { isOpen
      && (
      <GlobalModal
        Modal={Modal}
        updateProduct={this.updateProduct}
        toggleModal={this.toggleModal}
      >
        <AddGeneralProduct updateProduct={this.updateProduct} />
      </GlobalModal>
      )}

      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  addProduct: state.addProduct,
  updateCartTotal: state.updateCartTotal,
});
export default connect(mapStateToProps, { ...productActions })(products);
